<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" :destroyOnClose="true" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form"  :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="siteName" >
        <a-input v-model="form.siteName" :maxLength="16" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="分类" prop="categoryId" >
        <a-select placeholder="请选择分类" style="width: 100%" v-model="form.categoryId" allowClear>
          <a-select-option v-for="category in categoryList" :value="category.id">
            {{category.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="简介" prop="siteInfo" >
        <a-input v-model="form.siteInfo" :maxLength="32" placeholder="请输入简介" />
      </a-form-model-item>
      <a-form-model-item label="开放时间" prop="openTime" >
        <a-input v-model="form.openTime" :maxLength="16" placeholder="请输入开放时间12:00-14:00" />
      </a-form-model-item>
      <a-form-model-item label="预约价格" prop="preAmount" >
        <a-input v-model="form.preAmount" :maxLength="9" @change="limitPreAmount(form.preAmount)" placeholder="请输入预约价格" />
      </a-form-model-item>
      <a-form-item label="预约时间" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <div class="margin-bottom-10">
          <a-button type="primary" @click='addTime'>
            添加时间段
          </a-button>
        </div>
        <a-table :data-source="form.siteTimeSlotList" :columns="timeColumns" rowKey="id" :pagination="false" bordered>
          <div slot="startTimePeriod" slot-scope="text, record">
            <a-time-picker style="width: 100%" v-model="'2022-12-21 '+record.startTime" @change="(date,dateStr) =>{startTimePeriodChange(date,dateStr,record)}" format="HH:mm" allow-clear/>
          </div>
          <div slot="endTimePeriod" slot-scope="text, record">
            <a-time-picker style="width: 100%" v-model="'2022-12-21 '+record.endTime" @change="(date,dateStr) =>{endTimePeriodChange(date,dateStr,record)}" format="HH:mm" allow-clear/>
          </div>

          <div slot="price" slot-scope="text, record">
            <a-input v-model="record.price"  :maxLength="9" @change="limitPriceAmount(record)" placeholder="请输入金额" />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-popconfirm title="确定要删除此时段吗？" ok-text="确定" cancel-text="取消" @confirm="delTime(record)">
              <a href="javascript:void(0)" style="color:red;">删除</a>
            </a-popconfirm>
          </div>
        </a-table>
      </a-form-item>
      <a-form-model-item label="主图" prop="masterImg">
        <a-upload
          name="masterImg"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.masterImg"
            :src="form.masterImg"
            alt="masterImg"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="详情(多图)" prop="siteImgList">
        <a-upload
          name="siteImgList"
          v-model="fileList"
          listType="picture-card"
          :fileList="fileList"
          :remove="removeFile"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="true"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="是否上架" prop="isSale" >
        <a-radio-group v-model="form.isSale">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-item prop="siteDesc" label="场地详情" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <editor v-model="form.siteDesc"  destroyOnClose = "true" placeholder="请输入内容" />
      </a-form-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getSite, addSite, updateSite } from '@/api/res/site'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import {fileUpload} from "@/api/tool/common";
import {listCategory} from "@/api/res/category";
import moment from 'moment';
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    CustomDictTag,
  },
  data () {
    return {

      startTimePeriod: null,
      endTimePeriod: null,

      timeColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index'
        }, {
          title: '开始时间',
          dataIndex: 'startTime',
          key: 'startTime',
          scopedSlots: { customRender: 'startTimePeriod' }
        }, {
          title: '结束时间',
          dataIndex: 'endTime',
          key: 'endTime',
          scopedSlots: { customRender: 'endTimePeriod' }
        }, {
          title: '金额',
          dataIndex: 'price',
          key: 'price',
          scopedSlots: { customRender: 'price' }
        }, {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      count: 0,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      loading: '',
      categoryList:null,
      fileList: [],
      // 表单参数
      form: {
        id: null,
        siteTimeSlotList: [],
        siteName: null,
        masterImg:null,
        categoryId: null,
        siteImgList: [],
        siteDesc: null,
        siteInfo: null,
        siteTimeSlot: null,

        siteImg: null,

        openTime: null,

        preAmount: null,

        isSale: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        siteName: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '分类id ,外键res_category_id不能为空', trigger: 'blur' }
        ],
        siteTimeSlot: [
          { required: true, message: '场次 [{\"id\":\"23044\",\"startTime\":\"08:00\",\"endTime\":\"09:00\"}]不能为空', trigger: 'blur' }
        ],
        openTime: [
          { required: true, message: '开放开始时间不能为空', trigger: 'blur' }
        ],
        preAmount: [
          { required: true, message: '预约价格不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    moment,
    onClose () {
      this.open = false
      this.reset()
    },
    addTime() {
      const index = this.form.siteTimeSlotList.length+1;
      const newData = {
        index: index,
        startTime: "00:00",
        endTime: "00:00",
        price: null
      };
      this.form.siteTimeSlotList.push(newData);
      this.count = index;
    },
    /**
     * 删除等级
     */
    delTime(info) {
      this.form.siteTimeSlotList = this.form.siteTimeSlotList.filter(item => item.index !== info.index);
    },
    limitPreAmount(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.preAmount=val
    },
    limitPriceAmount(record) {
      // console.log(i);
      let val=record.price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      record.price = val
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getCategoryList () {
      listCategory({type:30}).then(response => {
        this.categoryList = response.data
      })
    },
    startTimeChange(date, dateString){
      this.form.startTime = dateString
    },
    endTimeChange(date, dateString){
      this.form.endTime = dateString
    },

    startTimePeriodChange(date, dateString,record){
      record.startTime = dateString
    },
    endTimePeriodChange(date, dateString, record) {
      record.endTime = dateString
    },
    // 表单重置
    reset () {
      this.formType = 1
        this.fileList = []
        this.form = {
        id: null,
        siteName: null,
        siteTimeSlotList: [],
        categoryId: null,
        masterImg:null,
        siteImgList: [],
        siteDesc: null,
        siteInfo: null,
        siteTimeSlot: null,
        siteImg: null,
        openTime: null,
        preAmount: null,
        isSale: null,
        createTime: null,
      }
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'site'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.masterImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.form.masterImg = res.data.fullPath
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

    //多图上传开始
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'site'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.fileList.push({status: 'done', url: res.data.fullPath, uid: this.getUidRandom(), name: 'image.jpg'})
          this.form.siteImgList= []
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.siteImgList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var siteImgList = []
      this.fileList = fileList
      fileList.forEach(e => {
        siteImgList.push(e.url)
      })
      this.form.siteImgList = siteImgList
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    //多图上传结束

    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSite({"id":id}).then(response => {
        this.form = response.data
        let idx = 1;
        this.form.siteTimeSlotList.forEach(e=>{
          e.index= idx;
          idx++;
        })
        this.fileList=[]
        var siteImgList = this.form.siteImgList ==null ? [] : this.form.siteImgList;
        for (var j = 0; j < siteImgList.length; j++) {
          this.fileList.push({status: 'done', url: siteImgList[j], uid: this.getUidRandom(), name: 'image.jpg'})
        }


        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSite(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSite(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.upload-btn {
  width: 200px;
  height: 200px;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
}
</style>
